export interface Slug {
  current: string | null
}

export const PERMISSIONS = {
  1: 'All',
  2: 'Logged In',
  3: 'Premium',
  4: 'Premium Yearly/Lifetime',
  5: 'Premium (with Data Projects)',
} as const

export type Permissions = (typeof PERMISSIONS)[keyof typeof PERMISSIONS]

export interface Error {
  message: string
}
