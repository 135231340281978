/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import type { GatsbyBrowser } from 'gatsby'
import { injectSpeedInsights } from '@vercel/speed-insights'
import '@stratascratch/style/theme.css'
import './src/styles/layout.css'
import { AuthProvider } from './src/lib/authentication'
import { API_URL } from './src/constants'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  injectSpeedInsights()
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <AuthProvider
    params={{
      APIUrl: API_URL || '',
    }}
  >
    {element}
  </AuthProvider>
)
